import type { JSONSchemaType } from 'ajv';
import type { IComponentImageField } from '~/typings/types/component.types';

export const strictImageSchema: JSONSchemaType<IComponentImageField> = {
  type: 'object',
  properties: {
    url: {
      type: 'string',
    },
    meta: {
      type: 'object',
      properties: {
        ratio: { type: 'number' },
        width: { type: 'number' },
        height: { type: 'number' },
      },
      required: ['ratio', 'width', 'height'],
      additionalProperties: false,
    },
  },
  required: ['url', 'meta'],
  additionalProperties: false,
};

export const nullableImageSchema: JSONSchemaType<IComponentImageField> = {
  type: 'object',
  nullable: true,
  properties: {
    url: {
      type: 'string',
    },
    meta: {
      type: 'object',
      properties: {
        ratio: { type: 'number' },
        width: { type: 'number' },
        height: { type: 'number' },
      },
      required: ['ratio', 'width', 'height'],
      additionalProperties: false,
    },
  },
  required: ['url', 'meta'],
  additionalProperties: false,
};
